import "./sidebar.scss"
import DashboardIcon from '@mui/icons-material/Dashboard';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'; // Import the Maps icon
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import SosIcon from '@mui/icons-material/Sos';
import PaidIcon from '@mui/icons-material/Paid';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone';
import LocalTaxiOutlinedIcon from '@mui/icons-material/LocalTaxiOutlined';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Navbar = () => {
  const navig = useNavigate()

  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to log out?");
    if (confirmLogout) {
        Cookies.remove('username');
        Cookies.remove('password');
        localStorage.removeItem('token');
        navig('/')
        window.location.reload();
       
    }
}

  return (
    <div className='sidebar'>
    <div className="top">
      <div className="mainlogocon">
      <Link to="/ " style={{textDecoration: "none",color:"#edca11"}}>
    <span className="logo" >HOLO CABS</span> 
    </Link>
      </div>
   
    
    </div>
    <hr/>
    <div className="center">
        <ul>
              <p className="title">MAIN</p>
              <Link to="/ " style={{textDecoration: "none"}}>

            <li>
            <DashboardIcon className="icon"/>
            <span>Dashboard</span>
            </li> 
            </Link>
        <p className="title">Lists</p>

        <a href="/users" style={{textDecoration: "none"}}>
            <li>
            <PeopleOutlineTwoToneIcon className="icon"/>
            <span>Users</span>
            </li>
         </a>

         <a href="/drivers " style={{textDecoration: "none"}}>
            <li>
            <LocalTaxiOutlinedIcon className="icon"/>
            <span>Drivers</span>
            </li>
            </a>  
            <a href="/ridehistory " style={{textDecoration: "none"}}>
            <li>
            <HistoryOutlinedIcon className="icon"/>
            <span>Ride History</span>
            </li>
            </a>
            <a href="/cancelledrides " style={{textDecoration: "none",color:"red"}}>
            <li>
            <CancelOutlinedIcon className="icon" color="red"/>
            <span >Cancelled Rides</span>
            </li>
            </a>
            
            <a href="/maps " style={{ textDecoration: "none" }}>
      <li>
        <MapOutlinedIcon className="icon" />
        <span>Maps</span>
      </li>
    </a>
    <a href="/activation " style={{ textDecoration: "none" }}>
  <li>
    <VpnKeyOutlinedIcon className="icon" />
    <span>Activation</span>
  </li>
</a>
<a href="/documents " style={{ textDecoration: "none" }}>
  <li>
    <AutorenewIcon className="icon" />
    <span>Documents Status</span>
  </li>
</a>
<a href="/driversos " style={{ textDecoration: "none" }}>
  <li>
    <SosIcon className="icon" />
    <span>Driver SOS</span>
  </li>
</a>
<a href="/passengersos " style={{ textDecoration: "none" }}>
  <li>
    <SosIcon className="icon" />
    <span>Passenger SOS</span>
  </li>
</a>
<a href="/settlement " style={{ textDecoration: "none" }}>
  <li>
    <PaidIcon className="icon" />
    <span>Settlement</span>
  </li>
</a>
<a href="/rewards " style={{ textDecoration: "none" }}>
  <li>
    <EmojiEventsIcon className="icon" />
    <span>Rewards</span>
  </li>
</a>
{/* <a href="/workduration " style={{ textDecoration: "none" }}>
  <li>
    <NoCrashIcon className="icon" />
    <span>Duty Count</span>
  </li>
</a> */}




    <li>
            {/* <LocalShippingOutlinedIcon className="icon"/>
            <span>Delivery</span>
            </li>
            <p className="title">USEFUL</p>
            <li>
            <InsertChartOutlinedTwoToneIcon className="icon"/>
            <span>Stats</span></li>
        
            <li>
            <NotificationsNoneIcon className="icon"/>
            <span>Notifications</span>
            </li>
            <p className="title">SERVICE</p>
            <li>
            <SettingsSystemDaydreamOutlinedIcon className="icon"/>
            <span>System Health</span>
            </li>
      
            <li>
            <PsychologyOutlinedIcon className="icon"/>
            <span>Logs</span>
            </li>
        
            <li>
            <SettingsOutlinedIcon className="icon"/>
            <span>Settings</span>
            </li>
            <p className="title">USER</p>
            <li>
            <AccountCircleOutlinedIcon className="icon"/>
            <span>Profile</span></li>
        
            <li> */}
            <ExitToAppOutlinedIcon className="icon"/>
            <span onClick={handleLogout} style={{color:"red"}}>Logout</span>
            </li>
        </ul>
    </div>
    {/* <div className="bottom">color options </div> */}
    </div>
  )
}

export default Navbar