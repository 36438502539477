import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import TaxiImage from './assets/taxi.png';
import io from 'socket.io-client';
import { Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

function MultipleMarkers() {
  const [taxiPositions, setTaxiPositions] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [showActiveOnly, setShowActiveOnly] = useState(false);
  const [selectedVehicleType, setSelectedVehicleType] = useState('');

  useEffect(() => {
    const socket = io('https://api.holocabs.in', {
      path: '/socket.io/'
    });
    socket.emit('givemeallinitialdriverdata');

    socket.on('getalldriver', (drivers) => {
      console.log(drivers);
      setDriverData(drivers);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const extractedData = driverData.map(item => ({
      lat: item.location.coordinates[1],
      long: item.location.coordinates[0],
      _id: item._id,
      name: item.driverName,
      isOnline: item.isAvailable,
      vehicleType: item.vehicleType,
    }));

    const newPositions = extractedData.map(({ lat, long }) => [parseFloat(lat), parseFloat(long)]);
    setTaxiPositions(newPositions);
  }, [driverData]);

  const taxiIcon = new Icon({
    iconUrl: TaxiImage,
    iconSize: [32, 32],
  });

  const handleShowActiveDrivers = () => {
    setShowActiveOnly(!showActiveOnly);
  };

  const handleVehicleTypeChange = (event) => {
    setSelectedVehicleType(event.target.value);
  };

  const filteredDriverData = driverData.filter(driver => {
    return (!showActiveOnly || driver.isAvailable) &&
           (!selectedVehicleType || driver.vehicleType === selectedVehicleType);
  });

  const filteredTaxiPositions = taxiPositions.filter((_, index) => {
    return (!showActiveOnly || driverData[index]?.isAvailable) &&
           (!selectedVehicleType || driverData[index]?.vehicleType === selectedVehicleType);
  });

  return (
    <div style={{ width: '80vw', height: '80vh', alignItems: 'center', justifyContent: 'center' }}>
      <MapContainer center={[20.4676, 85.8800]} zoom={12} style={{ width: '100%', height: '100%' }}>
        <TileLayer
          url="https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=JV7BaoJVv3hFbplu6etM"
          attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
        />
        {filteredTaxiPositions.map((position, index) => (
          <Marker key={index} position={position} icon={taxiIcon}>
            <Popup>
              Taxi location {index + 1} - {filteredDriverData[index]?.driverName}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      
      <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center', justifyContent: 'center' }}>
        <Button style={{ backgroundColor: '#6dbe75', marginRight: '10px' }} onClick={handleShowActiveDrivers}>
          <h2 style={{ color: 'white' }}>{showActiveOnly ? 'SHOW ALL DRIVERS' : 'SHOW ACTIVE DRIVERS'}</h2>
        </Button>
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel>Vehicle Type</InputLabel>
          <Select
            value={selectedVehicleType}
            onChange={handleVehicleTypeChange}
            label="Vehicle Type"
          >
            <MenuItem value=""><em>All</em></MenuItem>
            <MenuItem value="Regular">Regular</MenuItem>
            <MenuItem value="Prime/Sedan">Prime/Sedan</MenuItem>
            <MenuItem value="SUV/Premium">SUV/Premium</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

export default MultipleMarkers;
