import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, Pagination } from '@mui/material';
import Sidebar from '../../components/sidebar/Sidebar';

const CancelledRides = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);

  useEffect(() => {
    // Fetch data from the server using Axios
    axios.get('https://api.holocabs.in/get-cancelled-rides')
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
      });
  }, []);

  // Calculate the index of the last item to be displayed on the current page
  const lastIndex = page * rowsPerPage;
  // Calculate the index of the first item to be displayed on the current page
  const firstIndex = lastIndex - rowsPerPage;
  // Get the current page of data to display
  const currentPageData = data.slice(firstIndex, lastIndex);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div style={{ display: 'flex', height: '100%' }}>
     <div style={{width:"12%"}}>
         <Sidebar/>
     </div>
      <div style={{ flex: 1, overflow: 'auto' }}>
        <h1 style={{padding:10}}>Cancelled Rides </h1>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{background:"red"}}>
              <TableRow>
                <TableCell style={{paddingLeft:10,paddingRight:10}}>Customer Name</TableCell>
                <TableCell>Mobile</TableCell>
                <TableCell>Pickup Location</TableCell>
                <TableCell>Drop Location</TableCell>
                <TableCell>Distance</TableCell>
                <TableCell>Type of Car</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Pick Lat.</TableCell>
                <TableCell>Pick Long.</TableCell>
                <TableCell>Destination Lat.</TableCell>
                <TableCell>Destination Long.</TableCell>
                <TableCell>ETD</TableCell>
                <TableCell>Commision</TableCell>
                <TableCell>GST Price</TableCell>
                <TableCell>Payment Gateway Charges</TableCell>
                <TableCell>Night Charges</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.userName}</TableCell>
                  <TableCell>{item.userMobile}</TableCell>
                  <TableCell>{item.plocation}</TableCell>
                  <TableCell>{item.dlocation}</TableCell>
                  <TableCell>{item.distance}</TableCell>
                  <TableCell>{item.typeOfCar}</TableCell>
                  <TableCell>{item.price}</TableCell>
                  <TableCell>{item.platitude}</TableCell>
                  <TableCell>{item.plongitude}</TableCell>
                  <TableCell>{item.dlatitude}</TableCell>
                  <TableCell>{item.dlongitude}</TableCell>
                  <TableCell>{item.timebyGoogle}</TableCell>
                  <TableCell>{item.commision}</TableCell>
                  <TableCell>{item.gstPrice}</TableCell>
                  <TableCell>{item.paymentgateWayCharges}</TableCell>
                  <TableCell>{item.nightCharges}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          count={Math.ceil(data.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default CancelledRides;
