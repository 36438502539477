import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getStatusCellStyle = (status) => {
  let backgroundColor = "white"; // Default background color

  if (status === "Valid") {
    backgroundColor = "#7CFC00"; // Lighter green for Valid
  } else if (status === "Expired") {
    backgroundColor = "#FF6347"; // Lighter red for Expired
  } else if (status === "Expiring Soon") {
    backgroundColor = "#FFA500"; // Lighter orange for Expiring Soon
  }

  return {
    backgroundColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px",
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  };
};

const DocsStats = () => {
  const [data, setData] = useState([]);

  const ValidateRC = (row) => {
    console.log("HITTING TO THIS RC", row.RCNumber);
    const alertMessage = "Are you sure you want to validate this RC?";

    if (window.confirm(alertMessage)) {
      if (row.RCNumber.length > 7 && row.RCNumber.length < 15) {
        const token =
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY5MDI2NTQ1MywianRpIjoiZTEzZDk1MTMtNjA2MS00YzM4LWE5MTktNDI2NGJhYWE1NWUwIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LmdvbGRlbmZveHRlY2hub2xvZ3lAc3VyZXBhc3MuaW8iLCJuYmYiOjE2OTAyNjU0NTMsImV4cCI6MjAwNTYyNTQ1MywidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbIndhbGxldCJdfX0.lyjKdVWZOhLnpLi9jQMbrluZ73PnT3yN4982Lw-sF4o"; // Replace with your API token

        const url = "https://kyc-api.surepass.io/api/v1/rc/rc-full";

        const data = {
          id_number: row.RCNumber + "", // Convert to string
        };

        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };

        axios
          .post(url, data, { headers })
          .then((response) => {
            // Handle success response
            if (response.data.data.rc_number) {
              toast.success("Driver data retrieved successfully from Surepass");

              // Send a PATCH request to update the driver's data in your backend
              axios
                .patch(
                  `https://api.holocabs.in/update-driver-docs-info/${row.ID}`,
                  {
                    UpdatedfitUpto: response.data.data.fit_up_to,
                    UpdatedpuccUpto: response.data.data.pucc_upto,
                    UpdatedinsuranceUpto: response.data.data.insurance_upto,
                  }
                )
                .then((response) => {
                  if (response.data.success) {
                    // Handle success, e.g., update the UI or show a success message
                    toast.success("Driver data updated successfully");
                    setTimeout(() => {
                      window.location.reload();
                    }, 7000); // 5000 milliseconds = 5 seconds
                  } else {
                    // Handle failure, show an error message or take appropriate action
                    toast.error("Failed to update driver data");
                  }
                })
                .catch((error) => {
                  toast.error("Error updating driver data:", error);
                });
            }
          })
          .catch((error) => {
            // Handle error
            console.error("Error:", error);
          });
      } else {
        toast.error("Please Enter Correct RC Number");
      }
    } else {
    }
  };

  const ValidateDL = (row) => {
    const alertMessage = "Are you sure you want to validate this DL?";

    if (window.confirm(alertMessage)) {
      if (row.dlNumber.length <= 20) {
        const token =
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY5MDI2NTQ1MywianRpIjoiZTEzZDk1MTMtNjA2MS00YzM4LWE5MTktNDI2NGJhYWE1NWUwIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LmdvbGRlbmZveHRlY2hub2xvZ3lAc3VyZXBhc3MuaW8iLCJuYmYiOjE2OTAyNjU0NTMsImV4cCI6MjAwNTYyNTQ1MywidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbIndhbGxldCJdfX0.lyjKdVWZOhLnpLi9jQMbrluZ73PnT3yN4982Lw-sF4o"; // Replace with your API token

        const url =
          "https://kyc-api.surepass.io/api/v1/driving-license/driving-license";

        const data = {
          id_number: row.dlNumber,
          dob: row.dl_DOB,
        };

        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };

        axios
          .post(url, data, { headers })
          .then((response) => {
            // Handle success response
            if (response.data.data.license_number) {
              toast.success(
                "Driver DL data retrieved successfully from surepass"
              );

              // Send a PATCH request to update the driver's data in your backend
              axios
                .patch(
                  `https://api.holocabs.in/update-driver-docs-info/${row.ID}`,
                  {
                    UpdateddlNumberUpto: response.data.data.doe,
                  }
                )
                .then((response) => {
                  if (response.data.success) {
                    // Handle success, e.g., update the UI or show a success message
                    toast.success("Driver data updated successfully");
                    setTimeout(() => {
                      window.location.reload();
                    }, 7000); // 5000 milliseconds = 5 seconds
                  } else {
                    // Handle failure, show an error message or take appropriate action
                    toast.error("Failed to update driver data");
                  }
                })
                .catch((error) => {
                  console.error("Error updating driver data:", error);
                });
            }
          })
          .catch((error) => {
            // Handle error
            console.error("Error:", error);
          });
      } else {
        toast.error("Please Enter Correct DL Number");
      }
    } else {
    }
  };

  useEffect(() => {
    axios
      .get("https://api.holocabs.in/drivers/getall-expiry")
      .then((response) => {
        setData(response.data.drivers.reverse());
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => (
        <div
          className="cellAction"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <div
            className="ValidateRCButton"
            onClick={() => ValidateRC(params.row)}
            style={{
              backgroundColor: "#edca11",
              color: "black",
              padding: "4px",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            Validate RC
          </div>
          <div
            className="ValidateDLButton"
            onClick={() => ValidateDL(params.row)}
            style={{
              backgroundColor: "#edca11",
              color: "black",
              padding: "4px",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
              marginLeft: 30,
            }}
          >
            Validate DL
          </div>
        </div>
      ),
    },
  ];

  const columns = [
    { field: "driverName", headerName: "Driver Name", width: 200 },
    { field: "driverMobile", headerName: "Driver Number", width: 200 },
    {
      field: "fitUpto.status",
      headerName: "Fitness",
      width: 200,
      renderCell: (params) => (
        <div style={getStatusCellStyle(params.row.fitUpto.status)}>
          {params.row.fitUpto.status}
        </div>
      ),
    },
    {
      field: "puccUpto.status",
      headerName: "Pollution",
      width: 200,
      renderCell: (params) => (
        <div style={getStatusCellStyle(params.row.puccUpto.status)}>
          {params.row.puccUpto.status}
        </div>
      ),
    },
    {
      field: "insuranceUpto.status",
      headerName: "Insurance",
      width: 200,
      renderCell: (params) => (
        <div style={getStatusCellStyle(params.row.insuranceUpto.status)}>
          {params.row.insuranceUpto.status}
        </div>
      ),
    },
    {
      field: "dlNumberUpto.status",
      headerName: "DL",
      width: 200,
      renderCell: (params) => (
        <div style={getStatusCellStyle(params.row.dlNumberUpto.status)}>
          {params.row.dlNumberUpto.status}
        </div>
      ),
    },
    ...actionColumn,
  ];

  return (
    <div className="docs-stats">
      <ToastContainer />
      <DataGrid
        className="datagrid"
        rows={data}
        columns={columns}
        getRowId={(row) => row.ID} // Specify the unique ID for each row
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />

      {/* Delete Confirmation Modal */}
      {/* Similar to the Datatable component */}
    </div>
  );
};

export default DocsStats;
