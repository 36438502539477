import "./widget.scss";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import CarIcon from "@mui/icons-material/DirectionsCar";
import ToggleOnIcon from "@mui/icons-material/ToggleOn"; // Online Drivers
import ToggleOffIcon from "@mui/icons-material/ToggleOff"; // Offline Drivers
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi"; // Drivers in Ride
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined"; // Vehicle Icons

import React, { useState, useEffect } from "react";
import axios from "axios";

const Widget = ({ type }) => {
  const [numberUser, setNumberUser] = useState(0);
  const [numberDriver, setNumberDriver] = useState(0);
  const [onlineDrivers, setOnlineDrivers] = useState(0);
  const [offlineDrivers, setOfflineDrivers] = useState(0);
  const [ridingDrivers, setRidingDrivers] = useState(0);
  const [regularVehicles, setRegularVehicles] = useState(0);
  const [primeSedanVehicles, setPrimeSedanVehicles] = useState(0);
  const [suvPremiumVehicles, setSuvPremiumVehicles] = useState(0);
  const [rideData, setRideData] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get("https://api.holocabs.in/getalluser", {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const updatedData = response.data;
        setNumberUser(updatedData.length);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get("https://api.holocabs.in/getalldriver", {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const drivers = response.data;
        setNumberDriver(drivers.length);
        const online = drivers.filter(driver => driver.isAvailable).length;
        const offline = drivers.filter(driver => !driver.isAvailable).length;
        const riding = drivers.filter(driver => driver.currentDriving).length;
        const regular = drivers.filter(driver => driver.vehicleType === "Regular").length;
        const primeSedan = drivers.filter(driver => driver.vehicleType === "Prime/Sedan").length;
        const suvPremium = drivers.filter(driver => driver.vehicleType === "SUV/Premium").length;

        setOnlineDrivers(online);
        setOfflineDrivers(offline);
        setRidingDrivers(riding);
        setRegularVehicles(regular);
        setPrimeSedanVehicles(primeSedan);
        setSuvPremiumVehicles(suvPremium);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          "https://api.holocabs.in/ride-history",
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        setRideData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const ridenumber = rideData.length;

  let data;
  switch (type) {
    case "user":
      data = {
        title: "Users",
        isMoney: false,
        link: "see all users",
        icon: (
          <PersonOutlineOutlinedIcon
            className="icon"
            style={{ color: "crimson", backgroundColor: "rgba(248,0,0,0.2)" }}
          />
        ),
      };
      break;

    case "order":
      data = {
        title: "Drivers",
        isMoney: false,
        link: "View all Drivers",
        icon: (
          <PersonOutlineOutlinedIcon
            className="icon"
            style={{ color: "black", backgroundColor: "#edca11" }}
          />
        ),
      };
      break;

    case "earning":
      data = {
        title: "Rides",
        isMoney: false,
        link: "View History",
        icon: (
          <CarIcon
            className="icon"
            style={{ backgroundColor: "rgba(0,128,0,0.2)", color: "green" }}
          />
        ),
      };
      break;

    case "balance":
      data = {
        title: "BALANCE",
        isMoney: true,
        link: "See details",
        icon: (
          <AccountBalanceOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(128,0,128,0.2)", color: "purple" }}
          />
        ),
      };
      break;

    case "onlineDrivers":
      data = {
        title: "Online Drivers",
        isMoney: false,
        link: "View all Online Drivers",
        icon: (
          <ToggleOnIcon
            className="icon"
            style={{ backgroundColor: "rgba(0,128,0,0.2)", color: "green" }}
          />
        ),
      };
      break;

    case "offlineDrivers":
      data = {
        title: "Offline Drivers",
        isMoney: false,
        link: "View all Offline Drivers",
        icon: (
          <ToggleOffIcon
            className="icon"
            style={{ backgroundColor: "rgba(255,0,0,0.2)", color: "red" }}
          />
        ),
      };
      break;

    case "ridingDrivers":
      data = {
        title: "Drivers in Ride",
        isMoney: false,
        link: "View all Drivers in Ride",
        icon: (
          <LocalTaxiIcon
            className="icon"
            style={{ backgroundColor: "black", color: "yellow" }}
          />
        ),
      };
      break;

    case "regularVehicles":
      data = {
        title: "Regular Vehicles",
        isMoney: false,
        link: "View all Regular Vehicles",
        icon: (
          <DirectionsCarFilledOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(128,128,128,0.2)", color: "gray" }}
          />
        ),
      };
      break;

    case "primeSedanVehicles":
      data = {
        title: "Prime/Sedan Vehicles",
        isMoney: false,
        link: "View all Prime/Sedan Vehicles",
        icon: (
          <DirectionsCarFilledOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0,128,0,0.2)", color: "green" }}
          />
        ),
      };
      break;

    case "suvPremiumVehicles":
      data = {
        title: "SUV/Premium Vehicles",
        isMoney: false,
        link: "View all SUV/Premium Vehicles",
        icon: (
          <DirectionsCarFilledOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(128,0,0,0.2)", color: "red" }}
          />
        ),
      };
      break;

    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.title === "Users" && numberUser}
          {data.title === "Drivers" && numberDriver}
          {data.title === "Rides" && ridenumber}
          {data.title === "BALANCE" && "XXXX"}
          {data.title === "Online Drivers" && onlineDrivers}
          {data.title === "Offline Drivers" && offlineDrivers}
          {data.title === "Drivers in Ride" && ridingDrivers}
          {data.title === "Regular Vehicles" && regularVehicles}
          {data.title === "Prime/Sedan Vehicles" && primeSedanVehicles}
          {data.title === "SUV/Premium Vehicles" && suvPremiumVehicles}
        </span>
        <span className="link">{data.link}</span>
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpOutlinedIcon />
          {data.title === "Users" && "25%"}
          {data.title === "Drivers" && "22%"}
          {data.title === "Rides" && "5%"}
          {data.title === "BALANCE" && "X%"}
          {data.title === "Online Drivers" && "15%"}
          {data.title === "Offline Drivers" && "10%"}
          {data.title === "Drivers in Ride" && "8%"}
          {data.title === "Regular Vehicles" && "12%"}
          {data.title === "Prime/Sedan Vehicles" && "18%"}
          {data.title === "SUV/Premium Vehicles" && "20%"}
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
