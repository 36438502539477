import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DriverSOS = () => {
  const [data, setData] = useState([]);
  
  // Make a GET request to fetch SOS drivers' data
  useEffect(() => {
    axios
      .get('https://api.holocabs.in/getallsosdrivers') // Replace with your actual backend API URL
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching SOS driver data:', error);
      });
  }, []);

  // Define a function to handle resolving SOS for a driver
  const resolveSOS = (driverId) => {
    // Make a PATCH request to resolve SOS for a specific driver
    axios
      .patch(`https://api.holocabs.in/deletedriversos/${driverId}`)
      .then((response) => {
        if (response.data.success) {
          // Handle success, e.g., show a success message
          toast.success('SOS resolved successfully');
          // Optionally, update the data in state or reload the list
        } else {
          // Handle failure, e.g., show an error message
          toast.error('Failed to resolve SOS');
        }
      })
      .catch((error) => {
        // Handle error
        console.error('Error resolving SOS:', error);
      });
  };

  const columns = [
    { field: 'SOSDetails', headerName: 'Drivers SOS Details', width: 900, resizable: true }, // Make the column resizable
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => (
        <div className="cellAction" style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className="ResolveButton"
            onClick={() => resolveSOS(params.row._id)} // Pass the driverId for resolving SOS
            style={{
              backgroundColor: '#7CFC00', // Green color
              color: 'black',
              padding: '4px',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Resolve
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="driver-sos">
      <ToastContainer />
      <DataGrid
        className="datagrid"
        rows={data}
        columns={columns}
        getRowId={(row) => row._id} // Specify the unique ID for each row
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default DriverSOS;


