import React, { useState, useEffect } from "react";
import axios from "axios"; // You'll need to install axios for making API requests
import { Link, useNavigate, useParams } from "react-router-dom";

function EditDrivers() {
  const id = useParams().id;
  // const [DriversDetails, setDriversDetails] = useState([]);
  const [formData, setFormData] = useState({
    driverName: "",
    driverMobile: "",
    driverGender: "",
    driver_dob: "",
    carOwner: "",
    carregistrationDate: "",
    carModal: "",
    fuelType: "",
    fitUpto: "",
    insuranceCompany: "",
    insurancePolicyNumber: "",
    seatCapacity: "",
    puccUpto: "",
    adharNo: "",
    panNumber: "",
    dlNumber: "",
    RCNumber: "",
    address: "",
    upiId: "",
    bankAccountNumber: "",
    ifscCode: "",
    fcmToken: "",
    totalIncome: 0,
    vehicleType: "",
    DLPhotourl: "",
    AadharPhotourl: "",
    insuranceUpto: "",
    dlNumberUpto: "",
    dl_DOB:""
  });

  useEffect(() => {
    const getDriversDetails = async () => {
      try {
        const response = await axios.get(
          `https://api.holocabs.in/getdriverdetails/${id}`
        );
        const driverData = response.data;

        // Update the formData state with driverData
        setFormData({
          driverName: driverData.driverName,
          driverMobile: driverData.driverMobile,
          driverGender: driverData.driverGender,
          driver_dob: driverData.driver_dob,
          carOwner: driverData.carOwner,
          carregistrationDate: driverData.carregistrationDate,
          carModal: driverData.carModal,
          fuelType: driverData.fuelType,
          fitUpto: driverData.fitUpto,
          insuranceCompany: driverData.insuranceCompany,
          insurancePolicyNumber: driverData.insurancePolicyNumber,
          seatCapacity: driverData.seatCapacity,
          puccUpto: driverData.puccUpto,
          adharNo: driverData.adharNo,
          panNumber: driverData.panNumber,
          dlNumber: driverData.dlNumber,
          RCNumber: driverData.RCNumber,
          address: driverData.address,
          upiId: driverData.upiId,
          bankAccountNumber: driverData.bankAccountNumber,
          ifscCode: driverData.ifscCode,
          fcmToken: driverData.fcmToken,
          totalIncome: driverData.totalIncome,
          vehicleType: driverData.vehicleType,
          DLPhotourl: driverData.dlphotouri,
          AadharPhotourl: driverData.aadharphotouri,
          insuranceUpto: driverData.insuranceUpto,
          dlNumberUpto: driverData.dlNumberUpto,
          dl_DOB:driverData.dl_DOB
        });

        // setDriversDetails(driverData);
      } catch (err) {
        // Handle error
      }
    };

    getDriversDetails();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const navig = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.patch(
        `https://api.holocabs.in/update-driver-admin/${id}`,
        formData
      ); // Update the URL with your actual API endpoint
      if (response.data.success === true) {
        alert("Update Success");
        navig(`/drivers/${id}`);
      } else {
        alert("Error while Updating");
      }
      // Handle the response as needed
    } catch (error) {
      console.error(error);
    }
  };

  const [hoveredImage, setHoveredImage] = useState(null);
  const [modalImage, setModalImage] = useState(null);

  const handleMouseEnter = (image) => {
    setHoveredImage(image);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const openModal = (imageUrl) => {
    setModalImage(imageUrl);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <div style={styles.container}>
      <div className="custom-navbar" style={styles.navbar}>
        <div>
          <h2 style={styles.logo}>
            <Link to="/" style={styles.logoLink}>
              Holocabs
            </Link>
          </h2>
        </div>
        <div style={styles.navLinks}>
          <Link style={styles.navLink} to="/">
            Home
          </Link>
          <Link style={styles.navLink} to="/drivers">
            Drivers
          </Link>
          <Link style={styles.navLink} to="/users">
            Users
          </Link>
          <Link style={styles.navLink} to="/ridehistory">
            Ride History
          </Link>
        </div>
      </div>
      <div style={styles.content}>
        <h1 style={styles.header}>Edit Driver Informations</h1>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.formFields}>
            {renderInput('Driver Name', 'driverName', formData, handleChange)}
            {renderInput('Driver Mobile', 'driverMobile', formData, handleChange)}
            {renderInput('Driver Gender', 'driverGender', formData, handleChange)}
            {renderInput('Driver DOB (YYYY-MM-DD)', 'driver_dob', formData, handleChange)}
            {renderInput('Car Owner', 'carOwner', formData, handleChange)}
            {renderInput('Car Registration Date (YYYY-MM-DD)', 'carregistrationDate', formData, handleChange)}
            {renderInput('Car Model', 'carModal', formData, handleChange)}
            {renderInput('Fuel Type', 'fuelType', formData, handleChange)}
            {renderInput('Fit Upto', 'fitUpto', formData, handleChange)}
            {renderInput('Insurance Company', 'insuranceCompany', formData, handleChange)}
            {renderInput('Insurance Policy Number', 'insurancePolicyNumber', formData, handleChange)}
            {renderInput('Insurance Expiry', 'insuranceUpto', formData, handleChange)}
            {renderInput('Seat Capacity', 'seatCapacity', formData, handleChange)}
            {renderInput('PUC Upto', 'puccUpto', formData, handleChange)}
            {renderInput('Aadhar Number', 'adharNo', formData, handleChange)}
            {renderInput('PAN Number', 'panNumber', formData, handleChange)}
            {renderInput('Driver\'s License Number', 'dlNumber', formData, handleChange)}
            {renderInput('Driving License Expiry (YYYY-MM-DD)', 'dlNumberUpto', formData, handleChange)}
            {renderInput('Driving License DOB (YYYY-MM-DD)', 'dl_DOB', formData, handleChange)}
            {renderInput('RC Number', 'RCNumber', formData, handleChange)}
            {renderInput('Address', 'address', formData, handleChange)}
            {renderInput('UPI ID', 'upiId', formData, handleChange)}
            {renderInput('Bank Account Number', 'bankAccountNumber', formData, handleChange)}
            {renderInput('IFSC Code', 'ifscCode', formData, handleChange)}
            <div style={styles.label}>
              <label>
                FCM Token (Not Editable):
                <input
                  type="text"
                  disabled
                  name="fcmToken"
                  value={formData.fcmToken}
                  onChange={handleChange}
                  style={styles.input}
                />
              </label>
            </div>
            <div style={styles.label}>
              <label>
                Total Income:
                <input
                  type="number"
                  name="totalIncome"
                  value={formData.totalIncome}
                  onChange={handleChange}
                  style={styles.input}
                />
              </label>
            </div>
            <div style={styles.label}>
              <label>
                Vehicle Type:
                <select
                  name="vehicleType"
                  value={formData.vehicleType}
                  onChange={handleChange}
                  style={styles.input}
                >
                  <option value="Regular">Regular</option>
                  <option value="Prime/Sedan">Prime/Sedan</option>
                  <option value="SUV/Premium">SUV/Premium</option>
                </select>
              </label>
            </div>
          </div>
         
          <div style={styles.imageContainer}>
            <div
              style={styles.imageWrapper}
              onMouseEnter={() => handleMouseEnter('DL')}
              onMouseLeave={handleMouseLeave}
              onClick={() => openModal(formData.DLPhotourl)}
            >
              
              <img
                src={formData.DLPhotourl}
                alt="Driver's License"
                style={hoveredImage === 'DL' ? { ...styles.image, ...styles.imageHover } : styles.image}
              />
            </div>
            <div
              style={styles.imageWrapper}
              onMouseEnter={() => handleMouseEnter('Aadhar')}
              onMouseLeave={handleMouseLeave}
              onClick={() => openModal(formData.AadharPhotourl)}
            >
        
              <img
                src={formData.AadharPhotourl}
                alt="Aadhar"
                style={hoveredImage === 'Aadhar' ? { ...styles.image, ...styles.imageHover } : styles.image}
              />
            </div>
          </div>

          <button type="submit" style={styles.submitButton}>
            Save Changes
          </button>
        </form>
      </div>

      {modalImage && (
        <div style={styles.modal} onClick={closeModal}>
          <div style={styles.modalContent}>
            <img src={modalImage} alt="Full View" style={styles.modalImage} />
          </div>
        </div>
      )}
    </div>
  );
};

const renderInput = (label, name, formData, handleChange) => (
  <div style={styles.label}>
    <label>
      {label}:
      <input
        type="text"
        name={name}
        value={formData[name]}
        onChange={handleChange}
        style={styles.input}
      />
    </label>
  </div>
);

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#edca11',
    minHeight: '100vh',
    padding: '0 20px',
    boxSizing: 'border-box',
  },
  navbar: {
    width: '100%',
    position: 'fixed',
    top: 0,
    zIndex: 1000,
    background: '#fff',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
  },
  logo: {
    marginLeft: '20%'
  },
  logoLink: {
    textDecoration: 'none',
    color: '#000',
  },
  navLinks: {
    display: 'flex',
    gap: '20px',
  },
  navLink: {
    textDecoration: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
    border: '1px solid #edca11',
    color: '#000',
    textAlign: 'center',
    transition: 'background-color 0.3s ease',
  },
  navLinkHover: {
    backgroundColor: '#edca11',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '80px',
    width: '100%',
    boxSizing: 'border-box',
  },
  header: {
    fontSize: '36px',
    fontWeight: 'bold',
    color: '#000',
    fontFamily: 'Arial, sans-serif',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
    marginBottom: '20px',
    textAlign: 'center',
  },
  form: {
    width: '100%',
    maxWidth: '600px',
    borderRadius: '10px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #ccc',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    background: '#fff',
  },
  formFields: {
    width: '100%',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px',
    textAlign: 'left',
  },
  input: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginTop: '5px',
    transition: 'border-color 0.3s ease',
    boxSizing: 'border-box',
  },
  inputFocus: {
    borderColor: '#007bff',
  },
  submitButton: {
    padding: '10px 20px',
    background: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    marginTop: '20px',
  },
  submitButtonHover: {
    backgroundColor: '#0056b3',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    marginTop: '20px',
    
  },
  imageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '45%',
    cursor: 'pointer',
    
  },
  image: {
    width: '100%',
    height: 'auto',
    maxHeight: '200px',
    objectFit: 'contain',
    borderRadius: '5px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
  },
  imageHover: {
    transform: 'scale(1.2)',
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1001,
  },
  modalContent: {
    position: 'relative',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '5px',
  },
  modalImage: {
    width: '100%',
    height: 'auto',
    maxHeight: '90vh',
    objectFit: 'contain',
  },
};

export default EditDrivers;
