import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import upiqr from "upiqr";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PromoS = () => {
  const [promosData, setPromosData] = useState([]);
  const [selectedPromo, setSelectedPromo] = useState(null);
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [qrCodeData, setQrCodeData] = useState("");
  
  const [amount, setAmount] = useState("");

  useEffect(() => {
    // Fetch data from /get-all-promos route
    axios
      .get("https://api.holocabs.in/get-all-promos")
      .then((response) => setPromosData(response.data.promos.reverse()))
      .catch((error) =>
        console.error("Error fetching promotional data:", error)
      );
  }, []);

  const columns = [
    { field: "_id", headerName: "ID", hide: true },
    { field: "userName", headerName: "User Name", width: 150 },
    { field: "UpiID", headerName: "UPI ID", width: 150 },
    { field: "userMobile", headerName: "Number", width: 150 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: ({ row }) => (
        <>
          {row.isPaid ? (
            <CheckCircleIcon sx={{ color: "green" }} />
          ) : (
            <>
              <button
                onClick={() => handleDone(row)}
                style={{ marginRight: "8px" }}
                disabled={row.isPaid}
              >
                Done
              </button>
              <button
                onClick={() => handlePay(row)}
                style={{ marginRight: "8px" }}
                disabled={row.isPaid}
              >
                Pay
              </button>
            </>
          )}
        </>
      ),
    },
  ];

  const handleDone = (row) => {
    // Display a confirmation dialog before proceeding
    const isConfirmed = window.confirm("Are you sure you want to mark this as done?");
    
    if (isConfirmed) {
      // Make API call to /do-paid/:userMobile
      axios
        .post(`https://api.holocabs.in/do-paid/${row.userMobile}`)
        .then((response) => {
          // Handle success
          console.log("Done action successful:", response.data);
          // Optionally, you can refresh the data after successful completion
          // by making another request to /get-all-promos
          // axios.get('http://your-api-base-url/get-all-promos')
          //   .then(response => setPromosData(response.data.promos))
          //   .catch(error => console.error('Error fetching promotional data:', error));
        })
        .catch((error) => console.error("Error in Done action:", error));
    }
  };
  

  const handlePay = (row) => {
    // Show the modal and set selected promo
    setSelectedPromo(row);
    setQrModalVisible(true);
  };

  const generateQrCode = () => {

    
    // Generate QR code using upiqr library
    upiqr({
      payeeVPA: selectedPromo.UpiID,
      payeeName: selectedPromo.userName,
      amount: amount,
      transactionNote: "Love from Holo Cabs ❤️❤️",
      
    })
      .then((upi) => {
        const correctedIntent = decodeURIComponent(upi.intent);
        // Set the QR code data and show the modal
        setQrCodeData(upi.qr);
        setQrModalVisible(true);

        console.log("DTA", correctedIntent);
      
      })
      .catch((err) => {
        console.log("Error generating QR code:", err);
      });

      
  };

  



  return (
    <div>
      <DataGrid
        className="datagrid"
        columns={columns}
        rows={promosData}
        pageSize={10}
        getRowId={(row) => row._id}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />

      {qrModalVisible && (
        <div>
          <div>
            Amount:{" "}
            <input
              type="text"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
          <button onClick={generateQrCode}>Generate QR Code</button>
          <img src={qrCodeData} alt="QR Code" />



          
        </div>
      )}
    </div>
  );
};

export default PromoS;
