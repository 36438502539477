
import { useState, useEffect } from 'react';
import axios from 'axios';
import GetAppIcon from '@mui/icons-material/GetApp';
import format from 'date-fns/format';


export const userColumns = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'name', headerName: 'User Name', width: 200 },
  { field: 'referral', headerName: 'Referral ID', width: 200 },
  { field: 'mobile', headerName: 'User Mobile', width: 150 },
  { field: 'date', headerName: 'Date of Registeration', width: 150 },
];



export function useUserRows() {
  const [userRows, setUserRows] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get('https://api.holocabs.in/getalluser', {
      headers: {
        Authorization: `${token}`,
      },
    })
      .then(response => setUserRows(response.data))
      .catch(error => console.error('Error fetching data: ', error));
  }, []);

  return userRows;
}

const handleDownloadInfo = (driver) => {
  // Create a text representation of the driver's data
  const driverInfo = `Driver Name: ${driver.driverName}\nDriver Mobile: ${driver.driverMobile}\nAddress: ${driver.address}\nAadhar: ${driver.adharNo}`;

  // Create a Blob containing the data
  const blob = new Blob([driverInfo], { type: 'text/csv' });

  // Create a temporary URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create a temporary <a> element to trigger the download
  const a = document.createElement('a');
  a.href = url;
  a.download = `${driver.driverName}_info.csv`;
  a.style.display = 'none';

  // Append the <a> element to the body and trigger the download
  document.body.appendChild(a);
  a.click();

  // Clean up
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};



export const driverColumns = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'isAvailable', headerName: 'AVAILABLE', width: 100 },
  { field: 'driverName', headerName: 'Driver Name', width: 200 },
  { field: 'driverMobile', headerName: 'Driver Mobile', width: 150 },
  { field: 'address', headerName: 'Address', width: 250 },
  { field: 'adharNo', headerName: 'Aadhar', width: 150 },
  { field: 'bankAccountNumber', headerName: 'BANK ACCOUNT NUMBER', width: 200},
  { field: 'ifscCode', headerName: 'IFSC CODE', width: 200},
  { field: 'bankaccountholdername', headerName: 'BANK ACCOUNT HOLDER NAME', width: 200},


  {
    field: 'date',
    headerName: 'Date',
    width: 200,
    valueGetter: (params) => {
      const utcDate = params.row.date;
      const istDate = format(new Date(utcDate), 'yyyy-MM-dd HH:mm:ss', {
        timeZone: 'Asia/Kolkata',
      });
      return istDate;
    },
  },
  {
    field: 'info',
    headerName: 'Info',
    width: 120,
    renderCell: (params) => (
      <button
        onClick={() => handleDownloadInfo(params.row)}
        style={{
          backgroundColor: '#2196F3',
          border: 'none',
          color: 'white',
          padding: '4px',
          borderRadius: '4px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <GetAppIcon style={{ marginRight: '8px' }} />
        Download
      </button>
    ),
  },
];



export function useDriverRows() {
  const [driverRows, setDriverRows] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios
      .get('https://api.holocabs.in/getalldriver', {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const updatedData = response.data.map((row) => ({ ...row, id: row._id }));
        setDriverRows(updatedData);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
  }, []);

  return driverRows;
}


