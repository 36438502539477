import React,{useEffect,useState} from "react";

import "./featured.scss"
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import CircularProgressbar from "react-circular-progressbar";
import { CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from "axios";
const Featured = () => {
  const [rideData, setRideData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('https://api.holocabs.in/ride-history',{
          headers: {
            Authorization: `${token}`, 
          },
        });
        setRideData(response.data); // Update this line to set the correct data in the state
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);


const [totalPrice, setTotalPrice] = useState(0);

useEffect(() => {
  const calculatedTotalPrice = rideData.map(ride => ride.details.price)
   let sum = 0;
  for(let i=0;i<calculatedTotalPrice.length;i++){
       sum += parseFloat(calculatedTotalPrice[i]);    
   }
   setTotalPrice(sum)
}, [rideData]);
const percentage = (totalPrice / 10000) * 100;

// console.log(percentage + '%'); 

  return (
    <div className='featured'>
      <div className="top">
        <h1 className="title"> Total Revenue </h1>
        <MoreVertIcon fontSize="small"/>
      </div>
      <div className="bottom">
        <div className="featuredChart">
            {/* <CircularProgress value={70} text={"70%"}/> */}
            <CircularProgressbar value={percentage} text={percentage.toFixed(2)+"%"} strokeWidth={5} />
        </div>
        <p className="title"> Total revenue made today</p>
        <p className="amount">₹{totalPrice.toFixed(3)}</p>
        {totalPrice === 0 ?  <p>Calc...</p> : ''}
        <p className="desc"> previous transactions processing. last payment may be not included .</p>
        <div className="summary">
            <div className="item">
                <div className="itemTitle">Target</div>
                <div className="itemResult negative" >
                <KeyboardArrowDownIcon fontSize="small"/>
                    <div className="resultAmount">₹10000</div>
                </div>
            </div>

            <div className="item">
                <div className="itemTitle">Last Month</div>
                <div className="itemResult positive" >
                <KeyboardArrowUpIcon fontSize="small"/>
                    <div className="resultAmount">₹{totalPrice}</div>
                </div>
            </div>

            <div className="item">
                <div className="itemTitle">Profit</div>
                <div className="itemResult positive" >
                <KeyboardArrowUpIcon fontSize="small"/>
                    <div className="resultAmount">₹{"XXX"}</div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Featured