import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Modal from 'react-modal';
import { driverColumns, userColumns } from '../../datatablesource';

const Datatable = () => {
  
  const [data, setData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios
      .get('https://api.holocabs.in/getalluser', {
        headers: {
          Authorization: `${token}`, 
        },
      })
      .then((response) => {
        const updatedData = response.data.map((row) => ({ ...row, id: row._id }));
        setData(updatedData.reverse());
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleDelete = (id) => {
    setDeleteItemId(id);
    setShowDeleteModal(true);
  };
  const getCurrentURLLastPart = () => {
    const pathParts = window.location.pathname.split('/');
    const lastPart = pathParts[pathParts.length - 1];
    return lastPart;
  };


  const confirmDelete = () => {
    const currentURLLastPart = getCurrentURLLastPart();
    const deleteURL = currentURLLastPart != 'drivers'
      ? `https://api.holocabs.in/deleteuser/${deleteItemId}`
      : `https://api.holocabs.in/deletedriveraccountid/${deleteItemId}`;

    axios
      .delete(deleteURL)
      .then(() => {
        setData(data.filter((item) => item.id !== deleteItemId));
        setDeleteItemId(null);
        setShowDeleteModal(false);
      })
      .catch((error) => {
        console.error('Error deleting user:', error);
      });
  };

  const cancelDelete = () => {
    setDeleteItemId(null);
    setShowDeleteModal(false);
  };

  const actionColumn = [
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => (
        <div className="cellAction" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
          <div
            className="viewButton"
            onClick={() => {desiredValue =='drivers' ? navigate(`/drivers/${params.row.id}`) : navigate(`/users/${params.row.id}`)}}
            style={{
              backgroundColor: '#edca11',
              color: 'black',
              padding: '4px',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            View
          </div>
          <div
            className="deleteButton"
            onClick={() => handleDelete(params.row.id)}
            style={{
              backgroundColor: '#edca11',
              color: 'black',
              padding: '4px',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 10,
              marginLeft: 30,
            }}
          >
            Delete
          </div>
        </div>
      ),
    },
  ];


  const [driverData, setdriverData] = useState([]);

  const url = window.location.href;
  const parts = url.split("/");
  const desiredValue = parts[parts.length - 1];
 

    useEffect(() => {
      const token = localStorage.getItem('token');
      if(desiredValue == 'drivers'){
        axios
        .get('https://api.holocabs.in/getalldriver', {
          headers: {
            Authorization: `${token}`, 
          },
        })
        .then((response) => {
          const updatedData = response.data.map((row) => ({ ...row, id: row._id }));
          setData(updatedData.reverse());
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });


      }
     
    }, []);
  
  
  return (
    <div className="datatable">
    
    
    {desiredValue != 'drivers'  ?  <div className="datatableTitle" style={{marginTop:"10px",marginBottom:"10px",marginLeft:'10px',}}>
        Add new user :  
        <Link to="/users/new" style={{ textDecoration: 'none',padding:5,borderRadius:5,background:"purple",color:"#fff",marginLeft:5}} className="link">
           Add new
        </Link>
      </div> : ''} 
      <DataGrid
        className="datagrid"
        rows={data}
        columns={ desiredValue == 'drivers' ? driverColumns.concat(actionColumn) : userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={showDeleteModal}
        onRequestClose={cancelDelete}
        style={{
          content: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '300px',
            height: '150px',
            margin: 'auto',
          },
        }}
      >
        <h3>Confirm Delete</h3>
        <p>Are you sure you want to delete this USER?</p>
        <div>
      <button onClick={confirmDelete} style={{ backgroundColor: '#4CAF50', border: 'none', color: 'white', padding: '10px 20px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', fontSize: '16px', margin: '4px 2px', cursor: 'pointer', borderRadius: '4px' }}>Delete</button>
      <button onClick={cancelDelete} style={{ backgroundColor: '#2196F3', border: 'none', color: 'white', padding: '10px 20px', textAlign: 'center', textDecoration: 'none', display: 'inline-block', fontSize: '16px', margin: '4px 2px', cursor: 'pointer', borderRadius: '4px' }}>Cancel</button>
    </div>
      </Modal>
    </div>
  );
};

export default Datatable;
