import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PassengerSOS = () => {
  const [data, setData] = useState([]);

  // Make a GET request to fetch SOS passengers' data
  useEffect(() => {
    axios
      .get('https://api.holocabs.in/getallsospassengers') // Replace with your actual backend API URL
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching SOS passenger data:', error);
      });
  }, []);

  // Define a function to handle deleting SOS for a passenger
  const deletePassengerSOS = (passengerId) => {
    // Make a PATCH request to delete SOS for a specific passenger
    axios
      .patch(`https://api.holocabs.in/deletepassengersos/${passengerId}`)
      .then((response) => {
        if (response.data.success) {
          // Handle success, e.g., show a success message
          toast.success('SOS for passenger deleted successfully');
          // Optionally, update the data in state or reload the list
        } else {
          // Handle failure, e.g., show an error message
          toast.error('Failed to delete SOS for passenger');
        }
      })
      .catch((error) => {
        // Handle error
        console.error('Error deleting SOS for passenger:', error);
      });
  };

  const columns = [
    { field: 'SOSDetails', headerName: 'Passengers SOS Details', width: 900, resizable: true }, // Make the column resizable
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => (
        <div className="cellAction" style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            className="DeleteButton"
            onClick={() => deletePassengerSOS(params.row._id)} // Pass the passengerId for deleting SOS
            style={{
                backgroundColor: '#7CFC00', // Green color
                color: 'black',
                padding: '4px',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
          >
            Resolve
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="passenger-sos">
      <ToastContainer />
      <DataGrid
        className="datagrid"
        rows={data}
        columns={columns}
        getRowId={(row) => row._id} // Specify the unique ID for each row
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default PassengerSOS;
